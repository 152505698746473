// extracted by mini-css-extract-plugin
export var alignLeft = "B_qF d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qG d_fr d_bH d_dx";
export var container = "B_mT d_dW d_Z";
export var containerFull = "B_sM d_dT d_Z";
export var carouselWrapper = "B_k6 d_w d_bz d_bD";
export var design1Elements = "B_sN";
export var step1 = "B_sP";
export var step1ContentWrapper = "B_sQ";
export var step2 = "B_sR d_b5";
export var top1 = "B_sS d_w d_bC d_bP d_cs";
export var top2 = "B_sT B_sS d_w d_bC d_bP d_cs";
export var design2 = "B_sV";
export var line = "B_fk d_w";