// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "D_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "D_fR d_fR d_bz d_bJ";
export var menuDesign6 = "D_s8 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "D_s9 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "D_tb d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "D_tc d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "D_td d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "D_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "D_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "D_tf";
export var navbarItem = "D_nb d_bx";
export var navbarLogoItemWrapper = "D_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "D_tg d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "D_th d_gd d_by d_Z d_bs";
export var burgerToggle = "D_tj d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "D_tk d_gd d_by d_Z d_bs";
export var burgerInput = "D_tl d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "D_tm d_f3 d_w d_H";
export var burgerLine = "D_tn d_f1";
export var burgerMenuDesign6 = "D_tp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "D_tq d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "D_tr d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "D_ts d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "D_tt d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "D_tv d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "D_tw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "D_tx d_bC d_bP";
export var compact = "D_ty";
export var navDivided = "D_tz";
export var staticBurger = "D_tB";
export var menu = "D_tC";
export var navbarDividedLogo = "D_tD";
export var nav = "D_tF";
export var fixed = "D_tG";