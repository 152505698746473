// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "z_sz d_gS d_cw d_dv";
export var quoteParagraphCenter = "z_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "z_gV d_gV d_cw d_dx";
export var quoteRowLeft = "z_sB d_bG";
export var quoteRowCenter = "z_sC d_bD";
export var quoteRowRight = "z_sD d_bH";
export var quoteWrapper = "z_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "z_gR d_gR";
export var quoteExceptionSmall = "z_sF H_sF";
export var quoteExceptionNormal = "z_sG H_sG";
export var quoteExceptionLarge = "z_sH H_sH";
export var quoteAuthorExceptionSmall = "z_sJ H_sJ";
export var quoteAuthorExceptionNormal = "z_sK H_sK";
export var quoteAuthorExceptionLarge = "z_sL H_sL";