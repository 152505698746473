// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rc d_fp d_bG d_dv";
export var alignLeft = "s_qF d_fp d_bG d_dv";
export var alignDiscCenter = "s_rd d_fq d_bD d_dw";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignDiscRight = "s_rf d_fr d_bH d_dx";
export var alignRight = "s_qG d_fr d_bH d_dx";
export var footerContainer = "s_rg d_dW d_bW";
export var footerContainerFull = "s_rh d_dT d_bW";
export var footerHeader = "s_kf d_kf";
export var footerTextWrapper = "s_rj d_w";
export var footerDisclaimerWrapper = "s_km d_km d_ck";
export var badgeWrapper = "s_rk d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "s_rl d_bz d_bJ d_bN d_bL";
export var wide = "s_rm d_cy";
export var right = "s_rn d_bK";
export var line = "s_fk d_fl d_cv";
export var badgeDisclaimer = "s_rp d_bC d_bP d_bJ";
export var badgeContainer = "s_rq d_bz d_bH d_bP";
export var badge = "s_rr";
export var padding = "s_rs d_c7";
export var end = "s_rt d_bH";
export var linkWrapper = "s_rv d_dB";
export var link = "s_mZ d_dB";
export var colWrapper = "s_rw d_cx";
export var consent = "s_f d_f d_bC d_bP";
export var disclaimer = "s_rx d_bz d_bJ";
export var media = "s_ry d_bx d_dy";
export var itemRight = "s_rz";
export var itemLeft = "s_rB";
export var itemCenter = "s_rC";
export var exceptionWeight = "s_rD H_vc";