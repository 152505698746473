// extracted by mini-css-extract-plugin
export var noMargin = "p_qH d_cc";
export var carouselCol = "p_qJ d_cD";
export var carouselWrapper = "p_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "p_k7 d_k7 d_H d_w";
export var carouselContainer = "p_qK d_cD";
export var carouselContainerFull = "p_qL d_dT";
export var carouselImg = "p_mw d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "p_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "p_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "p_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "p_kZ d_kZ";
export var imageAltSmall = "p_qM d_k4";
export var imageAltNormal = "p_qN d_k4";
export var imageAltLarge = "p_qP d_k4";
export var imageTextSmall = "p_qQ d_k5";
export var imageTextNormal = "p_qR d_k5";
export var imageTextLarge = "p_qS d_k5";