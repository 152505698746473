// extracted by mini-css-extract-plugin
export var lbContainerOuter = "N_xC";
export var lbContainerInner = "N_xD";
export var movingForwards = "N_xF";
export var movingForwardsOther = "N_xG";
export var movingBackwards = "N_xH";
export var movingBackwardsOther = "N_xJ";
export var lbImage = "N_xK";
export var lbOtherImage = "N_xL";
export var prevButton = "N_xM";
export var nextButton = "N_xN";
export var closing = "N_xP";
export var appear = "N_xQ";