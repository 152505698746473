// extracted by mini-css-extract-plugin
export var socialContainer = "C_sW";
export var instagramLink = "C_sX";
export var socialWrapperLeft = "C_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "C_sY";
export var socialContentContainerFull = "C_sZ";
export var instagramContainer = "C_s0";
export var twitterContainer = "C_s1";
export var facebookContainer = "C_s2";
export var socialErrorContainer = "C_s3";
export var facebookContainerWide = "C_s4";
export var twitterContainerWide = "C_s5";
export var socialParagraphCenter = "C_s6";
export var instaWrapper = "C_s7";
export var SubtitleSmall = "C_qf H_qf H_tM H_tY";
export var SubtitleNormal = "C_qg H_qg H_tM H_tZ";
export var SubtitleLarge = "C_qh H_qh H_tM H_t0";
export var textLeft = "C_dv";
export var textCenter = "C_dw";
export var textRight = "C_dx";