// extracted by mini-css-extract-plugin
export var alignLeft = "v_qF d_bG";
export var alignCenter = "v_bP d_bD";
export var alignRight = "v_qG d_bH";
export var textAlignLeft = "v_rN";
export var textAlignCenter = "v_rP";
export var textAlignRight = "v_rQ";
export var hoursInnerWrapperAlt = "v_rR d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "v_rS d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "v_rT d_dw";
export var titleMargin = "v_rV d_cw";
export var hoursInnerInnerWrapper = "v_rW d_cz";