// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "J_vR d_bD";
export var storyRowWrapper = "J_hx d_hx d_bK";
export var storyLeftWrapper = "J_vS d_bz d_bP";
export var storyWrapperFull = "J_vT d_cD";
export var storyWrapperFullLeft = "J_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "J_mS d_hy";
export var storyLeftWrapperCenter = "J_vV d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "J_vW d_hF";
export var storyHeader = "J_vX d_hD d_w d_cs";
export var storyHeaderCenter = "J_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "J_hB d_hB d_by d_dw";
export var storyBtnWrapper = "J_vY d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "J_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "J_qz d_fg d_Z";
export var imageWrapperFull = "J_vZ d_w d_H d_bf d_Z";
export var SubtitleSmall = "J_qf H_qf H_tM H_tY";
export var SubtitleNormal = "J_qg H_qg H_tM H_tZ";
export var SubtitleLarge = "J_qh H_qh H_tM H_t0";
export var textLeft = "J_dv";
export var textCenter = "J_dw";
export var textRight = "J_dx";