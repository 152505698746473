// extracted by mini-css-extract-plugin
export var alignLeft = "x_qF d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_qG d_fr d_bH d_dx";
export var menuContainer = "x_sb d_dW";
export var menuContainerFull = "x_sc d_dT";
export var menuMainHeader = "x_jC d_jC d_w d_c3";
export var menuComponentWrapper = "x_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "x_jH d_jH d_cy";
export var menuComponentText = "x_jD d_jD d_cn";
export var menuComponentTextDesign2 = "x_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "x_jJ d_jJ d_w d_Z";