// extracted by mini-css-extract-plugin
export var carouselContainer = "M_qK d_w d_H d_bf d_Z";
export var carouselContainerCards = "M_wQ M_qK d_w d_H d_bf d_Z";
export var carouselContainerSides = "M_wR d_w d_H d_Z";
export var prevCarouselItem = "M_wS d_w d_H d_0 d_k";
export var prevCarouselItemL = "M_wT M_wS d_w d_H d_0 d_k";
export var moveInFromLeft = "M_wV";
export var prevCarouselItemR = "M_wW M_wS d_w d_H d_0 d_k";
export var moveInFromRight = "M_wX";
export var selectedCarouselItem = "M_wY d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "M_wZ M_wY d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "M_w0 M_wY d_w d_H d_Z d_bf";
export var nextCarouselItem = "M_w1 d_w d_H d_0 d_k";
export var nextCarouselItemL = "M_w2 M_w1 d_w d_H d_0 d_k";
export var nextCarouselItemR = "M_w3 M_w1 d_w d_H d_0 d_k";
export var arrowContainer = "M_w4 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "M_w5 M_w4 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "M_w6 M_w5 M_w4 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "M_w7 M_w4 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "M_w8";
export var nextArrowContainerHidden = "M_w9 M_w7 M_w4 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "M_kG d_0";
export var prevArrow = "M_xb M_kG d_0";
export var nextArrow = "M_xc M_kG d_0";
export var carouselIndicatorContainer = "M_xd d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "M_xf d_w d_bz d_bF";
export var carouselText = "M_xg d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "M_xh M_xg d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "M_xj d_b7";
export var carouselIndicator = "M_xk M_xj d_b7";
export var carouselIndicatorSelected = "M_xl M_xj d_b7";
export var arrowsContainerTopRight = "M_xm d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "M_xn d_0 d_bl d_bC";
export var arrowsContainerSides = "M_xp d_0 d_bl d_bC";
export var smallArrowsBase = "M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "M_xr M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "M_xs M_xr M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "M_xt M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "M_xv M_xt M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "M_qb";
export var iconSmall = "M_xw";
export var multipleWrapper = "M_xx d_bC d_bF d_bf";
export var multipleImage = "M_xy d_bC";
export var sidesPrevContainer = "M_xz M_xr M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "M_xB M_xr M_xq d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";