// extracted by mini-css-extract-plugin
export var campaignContainer = "n_qj d_dW";
export var campaignContainerFull = "n_qk d_dT";
export var wrapper = "n_ql d_w";
export var leftWrapper = "n_qm d_bz d_bN";
export var rightWrapper = "n_qn";
export var subtitle = "n_qp";
export var title = "n_qq";
export var text = "n_qd d_cs";
export var inlineWrapper = "n_qr d_bC d_bG d_w";
export var btnsWrapper = "n_qs n_qr d_bC d_bG d_w d_bJ";
export var priceWrapper = "n_qt n_qr d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "n_qv";
export var disclaimerWrapper = "n_qw d_cD";
export var mediaSlot = "n_qx";
export var listWrapper = "n_qy d_bC d_bP d_w";
export var btnWrapper = "n_d2";
export var imageWrapper = "n_qz d_fg d_Z";
export var SmallSmall = "n_qB H_qB H_tM H_t7";
export var SmallNormal = "n_qC H_qC H_tM H_t8";
export var SmallLarge = "n_qD H_qD H_tM H_t5";
export var textLeft = "n_dv";
export var textCenter = "n_dw";
export var textRight = "n_dx";
export var alignLeft = "n_qF";
export var alignCenter = "n_bP";
export var alignRight = "n_qG";